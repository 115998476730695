/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Modal from 'react-modal';
import OutsideClickHandler from 'react-outside-click-handler';
import { SVGRender } from '../../../../helper/image';
import './modalStyle.scss';
import H1 from '../../../atoms/Label/Headers/H1/clear';

import GeneralForm from '../../../molecules/ModalForm/General';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
};

class ModalChatbiz extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			whatsappNumber: '',
			storeName: '',
		};
	}

	componentDidUpdate(prevProps, prevStates) {
		const { trySubmit } = this.state;

		if (prevStates.trySubmit !== trySubmit) {
			this.onSubmit();
		}
	}

	onSubmit(storeName) {
		const { onSuccess, selectedPackage } = this.props;
		let msg = `Saya dari ${storeName} tertarik dengan Chatbiz.id`;
		if (selectedPackage) {
			msg = `Saya dari ${storeName} tertarik dengan paket ${selectedPackage} di Chatbiz.id`;
		}
		const win = window.open(
			`https://api.whatsapp.com/send?phone=6289520004505&text=${msg}&lang=en`,
			'_blank',
		);
		win.focus();
		onSuccess();
	}

	render() {
		const { whatsappNumber } = this.state;
		const { isModalOpen, onClose } = this.props;

		const renderHeader = () => {
			const { selectedPackage } = this.props;

			switch (selectedPackage) {
				case 'Basic':
					return <FormattedMessage id="modal.register.title_basic" />;

				case 'Enterprise':
					return <FormattedMessage id="modal.register.title_enterprise" />;

				default:
					return <FormattedMessage id="modal.register.title" />;
			}
		};

		return (
			<>
				<Modal
					isOpen={isModalOpen}
					// onAfterOpen={afterOpenModal}
					// onRequestClose={closeModal}
					style={customStyles}
					contentLabel="Main Modal Chatbiz"
					overlayClassName="OverlayMainChatbizModal"
				>
					<OutsideClickHandler
						onOutsideClick={() => {
							onClose();
						}}
					>
						<div className="modal-wrapper">
							<div className="modal-header">
								<button type="button" onClick={onClose} className="elipse-arrow-left">
									{SVGRender('https://seiturju.sirv.com/Chatbiz/ellipse-arrow-left.svg', '')}
								</button>
								<H1>{renderHeader()}</H1>
								{/* <CloseButton onClick={onClose} /> */}
							</div>
							<div className="modal-content">
								<GeneralForm
									onValidSubmit={data => {
										this.setState({
											whatsappNumber: data.whatsappNumber,
											storeName: data.businessName,
										});
										this.onSubmit(data.businessName);
									}}
									initialWhatsappNumber={whatsappNumber}
								/>
							</div>
						</div>
					</OutsideClickHandler>
				</Modal>
			</>
		);
	}
}

ModalChatbiz.defaultProps = {
	selectedPackage: '',
};

ModalChatbiz.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	selectedPackage: PropTypes.string,
};
export default ModalChatbiz;
