/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
// import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

// import { Helmet } from 'react-helmet';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fontsource/nunito';

import Menu from '../../../organisms/v2/Menu';
import BannerAboutUs from '../../../organisms/v2/BannerAboutUs';
import AboutUsTop from '../../../organisms/v2/AboutUsTop';
import AboutUsList from '../../../organisms/v2/AboutUsList';
// import IncreaseSale from '../../../organisms/v2/IncreaseSale';
import Inovation from '../../../organisms/v2/Inovation';
import ConnectWithUs from '../../../organisms/v2/Connect';
import Footer from '../../../organisms/Footer';
import ModalChatbiz from '../../../organisms/Modal/ModalChatbiz';
import withDefautlHoc from '../../../_hoc/global';

import withIntl from '../../../../helper/hoc/withIntl';

const AboutUsPage = ({ enPrefix }) => {
	const [modalOpen, setModalOpen] = useState(false);
	// const intl = useIntl();

	return (
		<>
			<Menu
				enPrefix={enPrefix}
				onDemoClick={() => {
					setModalOpen(true);
				}}
			/>
			<BannerAboutUs />
			<AboutUsTop />
			<AboutUsList />
			{/* <IncreaseSale /> */}
			<Inovation enPrefix={enPrefix} />
			<ConnectWithUs />
			<Footer enPrefix={enPrefix} />
			{modalOpen && (
				<ModalChatbiz
					isModalOpen={modalOpen}
					onClose={() => {
						setModalOpen(false);
					}}
					onSuccess={() => {
						setModalOpen(false);
					}}
				/>
			)}
		</>
	);
};

AboutUsPage.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(AboutUsPage));
