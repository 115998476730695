import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mobile } from '../../_Variable/var';

const Wrapper = styled.div`
	&.h1Default {
		font-family: Montserrat, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 1.401rem;
		line-height: 162%;
		color: #4f4f4f;
		@media only screen and (max-width: ${Mobile}) {
			font-size: 1.125rem;
			line-height: 120%;
		}
	}
`;

const H1 = ({ children, className }) => {
	return <Wrapper className={className || 'h1Default'}>{children}</Wrapper>;
};

H1.defaultProps = {
	className: '',
};

H1.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default H1;
