import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import { SVGRender } from '../../../../helper/image';
import H1 from '../../../atoms/Label/Headers/H1';
import H2 from '../../../atoms/Label/Headers/H2';
import Paragraph from '../../../atoms/Label/Paragraph';

import { Wrapper } from './style';

const Inovation = ({ enPrefix }) => {
	const intl = useIntl();

	return (
		<Wrapper>
			<div className="container">
				<div className="inovation-wrapper">
					<div className="text-wrapper">
						<H1>{intl.formatMessage({ id: `aboutus.invoation.title` })}</H1>
						<Paragraph>{intl.formatMessage({ id: `aboutus.invoation.sub` })}</Paragraph>
					</div>
					<div className="list-inovation-wrapper">
						<a href="https://tech.chatbiz.id/" target="__blank" className="inovation-item">
							<div className="inovation-item__image-wrapper">
								{SVGRender('https://seiturju.sirv.com/Chatbiz/v2/opensource.svg', 'ico-opensource')}
							</div>
							<div className="inovation-item__title">
								<H2>{intl.formatMessage({ id: `aboutus.invoation.opensource` })}</H2>
							</div>
						</a>
						<a
							href={enPrefix ? '/white-paper' : '/white-paper?lang=id'}
							target="__blank"
							className="inovation-item"
						>
							<div className="inovation-item__image-wrapper">
								{SVGRender('https://seiturju.sirv.com/Chatbiz/v2/whitepaper.svg', 'ico-whitepaper')}
							</div>
							<div className="inovation-item__title">
								<H2>{intl.formatMessage({ id: `aboutus.invoation.whitepaper` })}</H2>
							</div>
						</a>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

Inovation.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default Inovation;
