import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #ffffff;

	box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
	border-radius: 8px;
	position: relative;
	padding: 1.875rem 2.438rem 4.813rem 2.438rem;
`;

export default Wrapper;
