/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { Wrapper } from './style';
import InputWithLabel from '../../../atoms/Input/Text/WithLabel';
import ButtonPrimary from '../../../atoms/Button/Primary';
import useForm from '../../../../hooks/useForm';
import {
	generalForm as ValidateGeneralForm,
	isErrorFormWhatsapp,
	isErrorBusinessName,
} from './formHelper';
import { GenerateWhatsappNumberError, GenerateBusinessNameError } from './formHelper/validation';

const initialValueForm = withInitialPhoneNumber => {
	const data = {
		whatsappNumber: '',
		businessName: '',
	};

	if (withInitialPhoneNumber) {
		data.whatsappNumber = withInitialPhoneNumber;
	}

	return data;
};

const GeneralForm = ({ onValidSubmit, initialWhatsappNumber }) => {
	const intl = useIntl();
	const { values, errors, handleChange, handleSubmit } = useForm({
		initialValue: initialValueForm(initialWhatsappNumber),
		onSubmit: async dt => {
			if (!dt.isAnyErrors) {
				onValidSubmit(dt.values);
			}
		},
		withCustomValidation: (errValue, formValue) => ValidateGeneralForm(errValue, formValue),
	});

	const generateWANumberError = () => {
		return <GenerateWhatsappNumberError whatsappNumber={errors.whatsappNumber} />;
	};

	const generateBusinessNameError = () => {
		return <GenerateBusinessNameError businessName={errors.businessName} />;
	};

	return (
		<Wrapper onSubmit={handleSubmit}>
			<InputWithLabel
				label={intl.formatMessage({ id: `modal.register.phoneNumber` })}
				placeholder="+628xxxxxxxx"
				idInput="whatsappNumber"
				name="whatsappNumber"
				onChange={data => {
					handleChange(data);
				}}
				value={values.whatsappNumber}
				compError={generateWANumberError}
				isError={isErrorFormWhatsapp(errors.whatsappNumber)}
			/>

			<InputWithLabel
				label={intl.formatMessage({ id: `modal.register.bussinesName` })}
				placeholder="Contoh : PT Pemuda Inovasi Teknologi"
				idInput="businessName"
				name="businessName"
				onChange={data => {
					handleChange(data);
				}}
				value={values.businessName}
				compError={generateBusinessNameError}
				isError={isErrorBusinessName(errors.businessName)}
			/>

			<div className="submit-wrapper">
				<ButtonPrimary type="submit" id="mtModalDaftar">
					{intl.formatMessage({ id: `modal.register.button` })}
				</ButtonPrimary>
			</div>
		</Wrapper>
	);
};

GeneralForm.defaultProps = {
	initialWhatsappNumber: '',
};

GeneralForm.propTypes = {
	onValidSubmit: PropTypes.func.isRequired,
	initialWhatsappNumber: PropTypes.string,
};

export default GeneralForm;
