import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { SVGRender } from '../../../../helper/image';
import H1 from '../../../atoms/Label/Headers/H1';
import { Wrapper } from './style';

const ConnectWithUs = () => {
	const intl = useIntl();
	return (
		<Wrapper>
			<div className="topBackground" />
			<div className="container container-mobile">
				<div className="wrapper-list">
					<div className="content">
						<div className="text-connect-wrapper">
							<H1 className="h1Default title-connect">{intl.formatMessage({ id: `aboutus.connect` })}</H1>
						</div>
						<div className="button-connect-wrapper">
							<a href="mailto:hi@chatbiz.id" target="__blank">
								{SVGRender('https://seiturju.sirv.com/Chatbiz/email-white.svg', 'logo-email')}
							</a>
							<a href="https://wa.me/6289520004505" target="__blank">
								{SVGRender('https://seiturju.sirv.com/Chatbiz/whatsapp-white.svg', 'logo-wa')}
							</a>
							<a href="https://instagram.com/chatbiz.id" target="__blank">
								{SVGRender('https://seiturju.sirv.com/Chatbiz/instagram-white.svg', 'logo-ig')}
							</a>
							<a href="https://linkedin.com/company/chatbiz-id/" target="__blank">
								{SVGRender('https://seiturju.sirv.com/Chatbiz/linkedin-white.svg', 'logo-linkedin')}
							</a>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default ConnectWithUs;
