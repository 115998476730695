import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Helmet } from 'react-helmet';
import Favicon from '../assets/img/favicon.ico';
import AboutUs from '../componentsAtomic/templates/V2/AboutUs';
import useDetectId from '../hooks/useDetectId';
import NotFound from './404';
import '../assets/v2/default.scss';

const Render = () => {
	const intl = useIntl();
	const { isId } = useDetectId();
	if (isId) return <NotFound />;

	return (
		<>
			<Helmet title={intl.formatMessage({ id: `aboutus.meta.title` })}>
				<meta name="description" content={intl.formatMessage({ id: `aboutus.meta.desc` })} />
				<meta name="author" content="Chatbiz" />
				<meta name="theme-color" content="#04589b" />
				<link rel="icon" href={Favicon} />
				<html lang="id" />
				<link rel="stylesheet" href="/normalize.css" />

				<script src="https://cdn.jsdelivr.net/npm/sticksy/dist/sticksy.min.js" />
				<script src="/stickydemo.js" />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<AboutUs />
		</>
	);
};

export default Render;
