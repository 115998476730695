import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import MediumText from '../../../atoms/Label/MediumText';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';

import { Wrapper } from './style';

const BannerAboutUs = () => {
	const intl = useIntl();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Wrapper className="banner-home">
			<div className="container">
				<div className="row rowBannerHome">
					<div className="col-12">
						<div className="textWrapper">
							<MediumText className="topIndex mediumTextDefault">
								{intl.formatMessage({ id: `aboutus.title` })}
							</MediumText>
							<Paragraph className="topIndex pDefault">
								{intl.formatMessage({ id: `aboutus.sub` })}
							</Paragraph>
						</div>
					</div>
				</div>
			</div>
			<div className="quarterCircle" />
		</Wrapper>
	);
};

export default BannerAboutUs;
