import React from 'react';
import PropTypes from 'prop-types';

import H1AboutUs from '../../../atoms/Label/AboutUsList';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';

import { Wrapper } from './style';

const ItemAboutUs = ({ title, sub }) => {
	return (
		<Wrapper className="item-about-us">
			<H1AboutUs>{title}</H1AboutUs>
			<Paragraph>{sub}</Paragraph>
		</Wrapper>
	);
};

ItemAboutUs.propTypes = {
	title: PropTypes.string.isRequired,
	sub: PropTypes.string.isRequired,
};

export default ItemAboutUs;
