/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import ItemAboutUs from '../../../molecules/v2/ItemAboutUs';
import H1 from '../../../atoms/Label/Headers/H1/clear';
import { Wrapper } from './style';

const AboutUsList = () => {
	const intl = useIntl();
	return (
		<>
			<Wrapper>
				<div className="container container-about-us-lists">
					<div className="container-sticky">
						<div className="left-section">
							<div className="block block--sticky">
								<H1 className="h1Default left-section__title">
									{intl.formatMessage({ id: `aboutus.sticky_text.normal` })}
									<span> {intl.formatMessage({ id: `aboutus.sticky_text.bold` })}</span>
								</H1>
							</div>
						</div>
						<div className="right-section">
							<ItemAboutUs
								title={intl.formatMessage({ id: `aboutus.list_2.title` })}
								sub={intl.formatMessage({ id: `aboutus.list_2.sub` })}
							/>
							<ItemAboutUs
								title={intl.formatMessage({ id: `aboutus.list_3.title` })}
								sub={intl.formatMessage({ id: `aboutus.list_2.sub` })}
							/>
							<ItemAboutUs
								title={intl.formatMessage({ id: `aboutus.list_4.title` })}
								sub={intl.formatMessage({ id: `aboutus.list_2.sub` })}
							/>
						</div>
					</div>
				</div>
			</Wrapper>
		</>
	);
};

export default AboutUsList;
