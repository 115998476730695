import styled from 'styled-components';
import { MiniMobile, Mobile } from '../../../atoms/_Variable/var';

const MOBILE_BREAK_POINT = 1155;

export const Wrapper = styled.div`
	background-color: #ffffff;
	@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
		.container-mobile {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			max-width: 100%;
		}
	}

	.topBackground {
		background-color: #f9fbff;
		height: 120px;
	}
	.wrapper-list {
		margin-top: -90px;

		.content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: linear-gradient(242.15deg, #0661bd 25.09%, #066fc2 92.28%);
			box-shadow: 0px 4px 20px rgba(11, 74, 148, 0.2);
			border-radius: 8px;
			padding: 4.313rem 3.813rem;
			position: relative;

			@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
				align-items: center;
				flex-direction: column;
				padding: 1.938rem 0 2.865rem 0;
			}

			.text-connect-wrapper {
				width: 50%;
				@media screen and (max-width: 955px) {
					width: 50%;
				}
				@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
					width: 100%;
				}
				.title-connect {
					color: #ffffff;
					flex: 1;
					width: 80%;
					@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
						text-align: center;
						font-size: 1.375rem;
						width: 100%;
					}
				}
			}

			.button-connect-wrapper {
				@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
					margin-top: 1.25rem;
				}
				a {
					img {
						width: 60px;
						@media screen and (max-width: 955px) {
							width: 45px;
						}
						@media screen and (max-width: ${MiniMobile}) {
							width: 40px;
						}
					}
					&:hover {
						text-decoration: none;
					}
					&:not(:first-child) {
						margin-left: 4.313rem;
						@media screen and (max-width: ${Mobile}) {
							margin-left: 3rem;
						}
						@media screen and (max-width: ${MiniMobile}) {
							margin-left: 2.5rem;
						}
					}
				}
			}
		}
	}
`;

export default Wrapper;
