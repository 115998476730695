import styled from 'styled-components';
import { Tablet, Mobile, MiniMobile } from '../../../atoms/_Variable/var';

const MOBILE_RESPONSIVE = 850;

export const Wrapper = styled.div`
	background-color: #f9fbff;
	padding-top: 7.25rem;
	padding-bottom: 6.5rem;
	@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
		padding-bottom: 5.25rem;
	}
	.inovation-wrapper {
		display: flex;
		align-items: center;
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			flex-direction: column;
		}
		.text-wrapper {
			width: 49%;
			padding-right: 12%;
			@media only screen and (max-width: 950px) {
				width: 40%;
				padding-right: 5%;
			}
			@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
				width: 100%;
				padding-right: 0;
			}
		}
		.list-inovation-wrapper {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			@media only screen and (max-width: 950px) {
				width: 60%;
			}
			@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
				width: 100%;
			}
			.inovation-item {
				background: #ffffff;
				box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
				border-radius: 10px;
				width: 276px;

				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 3.375rem 1.5rem 1.5rem 1.5rem;
				@media only screen and (max-width: 1300px) {
					width: 250px;
				}
				@media only screen and (max-width: 1170px) {
					width: 240px;
					text-align: center;
					height: 350px;
				}
				@media only screen and (max-width: 1120px) {
					width: 220px;
				}
				@media only screen and (max-width: 1040px) {
					width: 200px;
					height: 320px;
				}
				@media only screen and (max-width: 950px) {
					width: 200px;
					padding-left: 1rem;
					padding-right: 1rem;
				}
				@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
					width: 276px;
					margin-top: 1.875rem;
				}
				@media only screen and (max-width: ${Tablet}) {
					width: 200px;
					height: 320px;
				}
				@media only screen and (max-width: ${Mobile}) {
					width: 159px;
					height: 250px;
					padding-top: 1.75rem;
				}
				@media only screen and (max-width: ${MiniMobile}) {
					width: 150px;
					height: 230px;
					padding-top: 1.75rem;
				}
				&:hover {
					text-decoration: none;
				}
				&__image-wrapper {
					img {
						width: 170px;
						@media only screen and (max-width: 1120px) {
							width: 150px;
						}
						@media only screen and (max-width: 1040px) {
							width: 130px;
						}
						@media only screen and (max-width: ${Mobile}) {
							width: 105px;
						}
						@media only screen and (max-width: ${MiniMobile}) {
							width: 80px;
						}
						/* @media only screen and (max-width: 1300px) {
							max-width: 98px;
						} */
					}
				}
				.h2Default {
					margin-top: 2.5rem;
				}
			}
		}
	}
`;

export default Wrapper;
