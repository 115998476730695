import styled from 'styled-components';

export const Wrapper = styled.form`
	.form-group {
		&:not(:first-child) {
			margin-top: 2.125rem;
		}
	}
	.submit-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 3.625rem;
		button {
			padding: 1.125rem 7.1875rem !important;
			text-transform: capitalize;
		}
	}
`;

export default Wrapper;
