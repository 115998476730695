import styled from 'styled-components';
import { BlueChatbiz, Tablet, MiniMobile, Large } from '../../../atoms/_Variable/var';

const MOBILE_RESPONSIVE = 1000;

export const Wrapper = styled.div`
	background-color: #f9fbff;
	padding-top: 6.938rem;
	padding-bottom: 9.375rem;
	@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
		padding-top: 0;
		padding-bottom: 2rem;
	}
	.container-about-us-lists {
		@media only screen and (max-width: ${Tablet}) {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			max-width: 100%;
		}
	}
	.container-sticky {
		display: flex;
		justify-content: flex-start;

		height: auto;
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			flex-direction: column;
			width: 100%;
		}
	}

	.left-section,
	.right-section {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			width: 100% !important;
		}
	}
	.left-section {
		width: 37%;
	}

	.right-section {
		width: 60%;
		padding-left: 5%;
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			align-items: center;
			justify-content: center;
			margin-top: 5rem;
		}
		@media only screen and (min-width: ${Large}) {
			margin-right: 6%;
		}

		.item-about-us {
			margin-right: 8%;
			@media only screen and (max-width: 1300px) {
				margin-right: 5%;
			}
			@media only screen and (max-width: 1250px) {
				margin-right: 0%;
			}
			@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
				max-width: 602px;
			}
			@media only screen and (max-width: ${Tablet}) {
				max-width: 92%;
				margin-left: -8%;

				.image-wrapper {
					bottom: -130px;
					right: -20px;
					img,
					picture {
						max-width: 320px;
						@media only screen and (max-width: ${MiniMobile}) {
							max-width: 280px;
						}
					}
				}
			}
			&:not(:first-child) {
				margin-top: 3rem;
			}
			&:last-child {
				margin-bottom: 3rem;
			}
		}
	}

	.block {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 1.25rem;
		height: 150px;
		color: #a6a6a6;
		width: 100%;
		margin: 15px;
	}
	.block--sticky {
		position: sticky;
		top: 120px;
		left: 0;
		color: #1e1e1e;
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			position: relative;
			top: 0;
			left: 0;
		}
	}
	.left-section {
		&__title {
			text-align: left;
			margin-top: 7.813rem;
			margin-bottom: 1.563rem;
			span {
				color: ${BlueChatbiz};
			}
		}
	}
`;

export default Wrapper;
