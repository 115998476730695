/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Slider from 'react-slick';

import H1 from '../../../atoms/Label/Headers/H1';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import { Wrapper } from './style';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	arrows: false,
};

const AboutUsTop = () => {
	const intl = useIntl();
	return (
		<Wrapper>
			<div className="container container-about-us-top">
				<div className="content">
					<div className="content__wrapper">
						<div className="image-wrapper">
							<Slider {...settings}>
								<div>
									<img
										src="https://seiturju.sirv.com/Chatbiz/v2/aboutus/1.png?format=webp"
										alt="aboutus-1"
									/>
								</div>
								<div>
									<img
										src="https://seiturju.sirv.com/Chatbiz/v2/aboutus/2.png?format=webp"
										alt="aboutus-2"
									/>
								</div>
								<div>
									<img
										src="https://seiturju.sirv.com/Chatbiz/v2/aboutus/3.png?format=webp"
										alt="aboutus-3"
									/>
								</div>
								<div>
									<img
										src="https://seiturju.sirv.com/Chatbiz/v2/aboutus/4.png?format=webp"
										alt="aboutus-4"
									/>
								</div>
								<div>
									<img
										src="https://seiturju.sirv.com/Chatbiz/v2/aboutus/team.png?format=webp"
										alt="aboutus-5"
									/>
								</div>
							</Slider>
						</div>
						<div className="text-wrapper">
							<div className="text-wrapper__title">
								<H1> {intl.formatMessage({ id: `aboutus.list_1.title` })}</H1>
							</div>
							<div className="text-wrapper__sub">
								<Paragraph>{intl.formatMessage({ id: `aboutus.list_1.sub` })}</Paragraph>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default AboutUsTop;
