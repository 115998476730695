/* eslint-disable react/prop-types */
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

export const GenerateWhatsappNumberError = ({ whatsappNumber }) => {
	const intl = useIntl();

	if (whatsappNumber === 'invalid-format' || whatsappNumber === 'invalid-phone-number') {
		return <>{intl.formatMessage({ id: `modal.register.error.invalid_phone` })}</>;
	}

	if (whatsappNumber === 'required') {
		return <>{intl.formatMessage({ id: `modal.register.error.required` })}</>;
	}

	return null;
};

export const GenerateBusinessNameError = ({ businessName }) => {
	const intl = useIntl();
	if (businessName === 'required') {
		return <>{intl.formatMessage({ id: `modal.register.error.required` })}</>;
	}
	return null;
};
