import styled from 'styled-components';
import { Large, Mobile, AverageSize, Tablet } from '../../../atoms/_Variable/var';

const MOBILE_RESPONSIVE = 1000;

export const Wrapper = styled.div`
	background-color: #f9fbff;
	padding-top: 200px;

	.container-about-us-top {
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			max-width: 100%;
		}
	}
	.slick-list {
		background-color: #f9fbff !important;
		border: none;
	}
	.slick-dots {
		padding-bottom: 10px;
		bottom: 8px;
		right: 0px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
		li {
			&:not(:last-child) {
				margin-right: 1.156rem;
				@media only screen and (max-width: ${Mobile}) {
					margin-right: 1rem;
				}
			}
			button {
				&::before {
					width: 17px;
					height: 17px;
					content: '';
					background: #ffffff !important;
					opacity: 1;
					border-radius: 50%;
					@media only screen and (max-width: ${Mobile}) {
						width: 14px;
						height: 14px;
					}
				}
			}

			&.slick-active {
				button {
					&::before {
						width: 17px;
						height: 17px;
						content: '';
						background: #ff8f1c !important;
						opacity: 1;
						@media only screen and (max-width: ${Mobile}) {
							width: 14px;
							height: 14px;
						}
					}
				}
			}
		}
	}
	.content {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 8%;
		@media only screen and (max-width: 1300px) {
			padding-right: 5%;
		}
		@media only screen and (max-width: 1250px) {
			padding-right: 0%;
		}
		@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
			justify-content: center;
		}
		&__wrapper {
			background: #ffffff;

			box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
			border-radius: 8px;
			max-width: 775px;
			@media only screen and (max-width: 800px) {
				width: 100%;
				max-width: 100%;
			}
		}

		.image-wrapper {
			position: relative;
			top: -130px;
			left: -350px;
			width: 547px;
			z-index: 99;
			@media only screen and (max-width: ${AverageSize}) {
				width: 450px;
				left: -250px;
				top: -100px;
			}
			@media only screen and (max-width: 1200px) {
				width: 380px;
				left: -160px;
				top: -50px;
			}
			@media only screen and (max-width: 1100px) {
				width: 340px;
				left: -120px;
			}
			@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
				left: 20%;
				right: 20%;
				width: 60%;
				top: -150px;
			}
			@media only screen and (max-width: 800px) {
				left: 10%;
				right: 10%;
				width: 80%;
			}

			@media only screen and (max-width: ${Tablet}) {
				left: 5%;
				right: 5%;
				width: 90%;
			}
			@media only screen and (min-width: ${Large}) {
				width: 450px;
				left: -250px;
				top: -50px;
			}
			img,
			picture {
				width: 100%;
			}
		}
		.text-wrapper {
			padding: 4.875rem 2.563rem 4.313rem 250px;
			position: relative;
			margin-top: -450px;
			@media only screen and (max-width: ${AverageSize}) {
				margin-top: -400px;
			}
			@media only screen and (max-width: 1200px) {
				margin-top: -320px;
			}
			@media only screen and (max-width: ${MOBILE_RESPONSIVE}px) {
				margin-top: -120px;
				padding-left: 2.563rem;
				padding-top: 0;
			}
			@media only screen and (min-width: ${Large}) {
				margin-top: -400px;
			}
		}
	}
`;
export default Wrapper;
