/* eslint-disable no-param-reassign */
import { phoneValidation } from '../../../../../helper/formHelper';

export const generalForm = (errValue, formValue) => {
	let isAnyError = false;

	const validation = phoneValidation(formValue.whatsappNumber);
	if (validation !== '') {
		isAnyError = true;
		errValue.whatsappNumber = validation;
	}
	if (formValue.whatsappNumber === '') {
		isAnyError = true;
		errValue.whatsappNumber = 'required';
	}

	if (formValue.businessName === '') {
		isAnyError = true;
		errValue.businessName = 'required';
	}
	return { errValue, isAnyError };
};

export const isErrorFormWhatsapp = phoneNumber => {
	return (
		phoneNumber === 'invalid-format' ||
		phoneNumber === 'invalid-phone-number' ||
		phoneNumber === 'required'
	);
};

export const isErrorBusinessName = businessName => {
	return businessName === 'required';
};

export default generalForm;
